import React, { useEffect, useState } from "react";
import './style.css';
import { NavLink } from 'react-router-dom';
import { FaChevronLeft } from "react-icons/fa";
import Marquee from "react-fast-marquee";
import { HiMenu } from 'react-icons/hi';
import { RiCloseLine } from 'react-icons/ri';
import { Images, Href, Icons } from '../../Constants';

const socialMediaButtonsData = [
    {
        LINKS: Href.GOSATS_WHATSAPP,
        ICONS: Icons.BsWhatsapp,
    },
    {
        LINKS: Href.GOSATS_TELEGRAM,
        ICONS: Icons.FaTelegramPlane,
    },
    {
        LINKS: Href.GOSATS_TWITTER,
        ICONS: Icons.FaTwitter,
    }
]

const Headder = () => {

    const [active, setActive] = useState(false);
    const [activeLink, setActiveLink] = useState("home");
    const [showMarquee, setShowMarquee] = useState(true);
    const targetDate = new Date('2025-04-08T23:59:59');
    const [remainingTime, setRemainingTime] = useState(calculateRemainingTime());
    const [showTimer, setShowTimer] = useState(false);

    useEffect(() => {
        // Update remaining time every second
        const timer = setInterval(() => {
            const updatedRemainingTime = calculateRemainingTime();
            if(updatedRemainingTime === 0){
                setShowMarquee(false)
            }
            setRemainingTime(updatedRemainingTime);
            if (updatedRemainingTime.days <= 2 && !showTimer) {
                setShowTimer(true);
            }
        }, 1000);

        // Clear the interval when the component unmounts
        return () => clearInterval(timer);
    }, []);

    const showMenu = () => {
        setActive(!active)
    }

    function calculateRemainingTime() {
        const now = new Date();
        const difference = targetDate - now;
        // Check if the target time has already passed
        if (difference < 0) {
            return 0;
        }
        // Convert the difference to hours, minutes, and seconds
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        return { hours, minutes, seconds, days };
    }

    return (
        <div className="header-container" style={{ width: '100%' }}>
            <div className="container col-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-between align-items-center">
                <div className="menu-icon">
                    <NavLink to='/'>
                        <img src={Images.logo} className={activeLink === 'home' ? 'col-md-6 brand-logo-web' : 'col-md-6 brand-logo-web'} alt="Logo" onClick={() => { setActive(false); setActiveLink('home'); }} />
                    </NavLink>
                    <button className='qr-button1'
                        onClick={(e) => {
                            e.preventDefault();
                            window.location.href = Href.GOSATS_ONELINK;
                        }}>
                        <img src={Images.qr} alt="" className='qr-image' />
                        Download the app
                    </button>
                    <HiMenu className="menu" onClick={showMenu} />
                </div>

                <nav className={active ? 'slider active' : 'slider'}>
                    <ul style={{ listStyleType: 'none' }}>
                        <div className="closed">
                            <RiCloseLine className="close" onClick={showMenu} style={{ color: '#FFFFFF' }} />
                        </div>

                        <div className="divNav">
                            <li>
                                <NavLink to='/' onClick={() => { setActive(false); setActiveLink('home'); }}>
                                    <img src={Images.logo} className={activeLink === 'home' ? 'col-md-6 brand-logo' : 'col-md-6 brand-logo'} alt="Logo" />
                                </NavLink>
                            </li>
                            <div className='navBarDivider'></div>
                            <li>
                                <NavLink to='/' onClick={() => { setActive(false); setActiveLink('home') }} className={activeLink === 'home' ? 'active-link' : 'footer-links-about'}>Home</NavLink>
                            </li>
                            <div className='navBarDivider'></div>
                            <li>
                                <NavLink to='/card' onClick={() => { setActive(false); setActiveLink('card') }} className={activeLink === 'card' ? 'active-link' : 'footer-links-card'}>Card</NavLink>
                            </li>
                            <div className='navBarDivider'></div>
                            <li>
                                <NavLink to='/satspay' onClick={() => { setActive(false); setActiveLink('satspay') }} className={activeLink === 'satspay' ? 'active-link' : 'footer-links-card'}>SatsPay</NavLink>
                            </li>
                            <div className='navBarDivider'></div>
                            {/* <li>
                            <a className='footer-links-nav col-lg-3 col-xl-4' href={Href.GOSATS_BLOG} >Blog</a>
                            </li>
                            <div className='navBarDivider'></div> */}
                            <li>
                                <a className='footer-links-careers me-4' href={Href.GOSATS_CAREERS}>Careers <span className="careerHiring">We're Hiring</span></a>
                            </li>
                            <div className='navBarDivider'></div>
                            <li>
                                <div className="navButtonDoenload">
                                    <button className='qr-button1'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.location.href = Href.GOSATS_ONELINK;
                                        }}>
                                        Download The App
                                    </button>
                                </div>
                                <button className='qr-button'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href = Href.GOSATS_ONELINK;
                                    }}>
                                    <img src={Images.buttonQR} alt="" className='qr-image' />
                                    Download The App
                                </button>
                                <div className=' qr-container'>
                                    <p>Download The App</p>
                                    <img src={Images.qr} className='qr-img' alt="QR Code" />
                                    {/* <div className='qrdivider'></div> */}
                                    {/* <div className="qrExcentation" onClick={() => window.location.href = Href.GOSATS_CHROME_EXTENCTION}>Install Chrome Extension <span className="qrArrow"><FaChevronLeft className="qrfa" /></span></div> */}
                                </div>
                                <li className="hamburgerSocialMedia">
                                    <HamburgerCommunity data={socialMediaButtonsData} />
                                </li>
                            </li>
                        </div>
                    </ul>
                </nav>
            </div>
            {
                showMarquee &&
                <div>
                    <div className="marqueeNavBarMob container">

                        <div className="marqueeContainer">
                        <div className='leftLayout'>
                        </div>
                            <Marquee speed={30} gradientColor='none' direction='left'>
                                <div className="timerFont">
                                    Get the Elite card for ₹699 only! &nbsp;&nbsp;
                                </div>
                            </Marquee>
                            <div className='campTimerContainer' style={{ width: "145px", marginLeft: '0px' }}>
                                <img alt=' ' className='timerIcon' src={'https://assets.gosats.io/notification/2024/12/1734692217708'} />
                                <div className='timeCounter'>
                                    {remainingTime === 0 ? (
                                        '00:00:00'
                                    ) : (
                                        <div style={{ display: 'flex', gap: '2px', marginBottom: '2px' }}>
                                           {remainingTime?.days > 2 ? `${remainingTime?.days} days` : `${String(remainingTime.days * 24 + remainingTime.hours).padStart(2, '0')}:${String(remainingTime.minutes).padStart(2, '0')}:${String(remainingTime.seconds).padStart(2, '0')}`}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="marqueeNavBar container">
                        <div className="marqueeContainer">
                            <div className="timerFont">
                                Get the Elite card for ₹699 only!
                            </div>
                            <div className='campTimerContainer' style={{ width: "200px", marginLeft: '0px' }}>
                                <span style={{color: '#FFFFFF'}}>Offer valid for</span>
                                <img alt=' ' className='timerIcon' src={'https://assets.gosats.io/notification/2024/12/1734692217708'} />
                                <div className='timeCounter'>
                                    {remainingTime === 0 ? (
                                        '00:00:00'
                                    ) : (
                                        <div style={{ display: 'flex', flexWrap: 'nowrap', gap: '2px', marginBottom: '2px' }}>
                                            {remainingTime?.days > 2 ? `${remainingTime?.days} days` : `${String(remainingTime.days * 24 + remainingTime.hours).padStart(2, '0')}:${String(remainingTime.minutes).padStart(2, '0')}:${String(remainingTime.seconds).padStart(2, '0')}`}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>

        /*  <div className='col-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-between  headder-container'>
             <div className="navBar">
                 <NavLink to='/'>
                     <img src={Images.logo} className=' col-md-6 brand-logo' alt="Logo" />
                 </NavLink>
                 <div className="navButtonDoenload">
                     <button className='qr-button'
                         onClick={(e) => {
                             e.preventDefault();
                             window.location.href = Href.GOSATS_ONELINK;
                         }}>
                         Download the app
                     </button>
                 </div>
                 <div style={{ color: 'white' }} className='menuIcon' onClick={handelclick}>
                     {
                         clicked ? <RiCloseLine /> : <FiMenu />
                     }
                 </div>
             </div>
 
             <ul className={clicked ? 'navButtons' :  "navButtons active"}>
                 <li className="mobLogo">
                     <NavLink to='/'>
                         <img src={Images.logo} className=' col-md-6 brand-logo2' alt="Logo" />
                     </NavLink>
                 </li>
                 <div className='mt-2 navDivider'></div>
                 <li>
                     <NavLink to='/about' className='footer-links-about me-4'>About</NavLink>
                 </li>
                 <div className='navDivider'></div>
                 <li className="navBlog">
                     <a className='footer-links col-lg-3 col-xl-4' href={Href.GOSATS_BLOG} >Blog</a>
                 </li>
                 <div className='navDivider'></div>
                 <li>
                     <a className='footer-links-careers me-4' href={Href.GOSATS_CAREERS}>Careers <span className="careerHiring">We're Hiring</span></a>
                 </li>
                 <div className='navDivider'></div>
                 <li>
                     <button className='qr-button'
                         onClick={(e) => {
                             e.preventDefault();
                             window.location.href = Href.GOSATS_ONELINK;
                         }}>
                         <img src={Images.qr} alt="" className='qr-image' />
                         Download the app
                     </button>
                     <div className='qr-container'>
                         <p>Download the App</p>
                         <img src={Images.qr} className='qr-img' alt="QR Code" />
                         <div className='qrnavDivider'></div>
                         <div className="qrExcentation" onClick={() => window.location.href = Href.GOSATS_CHROME_EXTENCTION}>Install Chrome Extension <span className="qrArrow"><FaChevronLeft className="qrfa" /></span></div>
                     </div>
                 </li>
                 <li className="hamburgerSocialMedia">
                     <HamburgerCommunity data={socialMediaButtonsData} />
                 </li>
             </ul>
         </div> */
    );
}

const HamburgerCommunity = ({ data }) => {
    return (
        <div className='navSocialmedia d-flex flex-wrap'>
            {
                data.map((data, index) => (
                    <div className='navbaricon' key={index}>
                        <a href={data.LINKS} className='nav-Social-media'>
                            <data.ICONS color='#A6A7A8' className='nav-icon' />
                        </a>
                    </div>
                ))
            }
        </div>
    )
}

export default Headder;